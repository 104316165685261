import React from 'react'
import _propType from 'prop-types'

import Modal from '../../lib/react-awesome-modal'
import { Row, Col } from 'react-flexbox-grid'
import ResponsiveEmbed from 'react-responsive-embed'
import { Icon } from '@blueprintjs/core'

// import closeIcon from "../../assets/images/window-close-solid.svg"
import './index.scss'

const VideoModal = ({ modalOpen, toggle, url, videoId, dlURL }) => (
  <Modal
    visible={modalOpen}
    effect="fadeInRight"
    onClickAway={toggle('modalOpen')}
  >
    <Row className="video-modal">
      <Col xs={12}>
        <div className={'video-modal-close'} onClick={toggle('modalOpen')}>
          <Icon icon="cross" iconSize={25} />
        </div>
        {/* <i id="hamburger-icon" className={`fa fa-window-close video-modal-close`} onClick={toggle('modalOpen')}>X</i> */}
        <div className="video-modal-content">
          <div id={videoId} className={'video-modal-container'}>
            <div
              className="wistia_responsive_padding"
              style={{
                padding: '56.25% 0 0 0',
                position: 'relative',
                paddingBottom: '2rem',
              }}
            >
              <div
                className="wistia_responsive_wrapper"
                style={{
                  height: '100%',
                  left: 0,
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                }}
              >
                <div
                  className={`wistia_embed wistia_async_${videoId} videoFoam=true`}
                  style={{
                    height: '100%',
                    position: 'relative',
                    width: '100%',
                  }}
                >
                  <div
                    className="wistia_swatch"
                    style={{
                      height: '100%',
                      left: 0,
                      opacity: 0,
                      overflow: 'hidden',
                      position: 'absolute',
                      top: 0,
                      transition: 'opacity 200ms',
                      width: '100%',
                    }}
                  >
                    <img
                      src={`https://fast.wistia.com/embed/medias/${videoId}/swatch?doNotTrack=true`}
                      style={{
                        filter: 'blur(5px)',
                        height: '100%',
                        objectFit: 'contain',
                        width: '100%',
                      }}
                      alt=""
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </Modal>
)

VideoModal.propTypes = {
  children: _propType.node,
  modalOpen: _propType.bool,
  toggle: _propType.func,
  url: _propType.string,
  videoId: _propType.string,
}

export default VideoModal
