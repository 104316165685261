const videoCollection = [
  {
    thumb: 'nurse_video_thumbnail.png',
    copy: `Hear from a mother – and nurse – whose child has been treated with <span class='block_nowrap'>SUPPRELIN<sup>&reg;</sup> LA</span>`,
    wistiaID: 'rucsd8tdxv',
    iframeUrl:
      '//fast.wistia.net/embed/iframe/rucsd8tdxv?videoFoam=true&doNotTrack=true',
    alt: 'Mother Nurse Video',
  },
  {
    thumb: 'davidlanning_video_thumbnail.png',
    copy: `When discussing the procedure with caregivers, leverage this video from an experienced peer`,
    wistiaID: 'eawbrakdin',
    iframeUrl:
      '//fast.wistia.net/embed/iframe/eawbrakdin?videoFoam=true&doNotTrack=true',
    alt: 'Experienced Peer Video',
  },
  {
    thumb: '5goodreasons_video_thumbnail.png',
    copy: `5 important considerations for your child with CPP`,
    wistiaID: '6w7292pjs8',
    iframeUrl:
      '//fast.wistia.net/embed/iframe/6w7292pjs8?videoFoam=true&doNotTrack=true',
    alt: '5 Important Considerations Video',
  },
]

export default videoCollection
