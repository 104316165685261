import React from "react"
import _propType from 'prop-types'

import VideoModal from "../video-modal"
import cx from "classnames"
import SourceEmitter from '../../lib/emitter'
import "./index.scss"

let modalSubscription = null


class VideoContainer extends React.Component {

  constructor () {
    super()
    this.state = {
        modalOpen: false,
      }
    this.toggle = this.toggle.bind(this)
    this.stopVideo = this.stopVideo.bind(this)
    this.initTracking = this.initTracking.bind(this);

  }

  componentWillUpdate(nextProps) {
    if (nextProps.directLink == true && this.props.directLink == false) {
      this.toggle("modalOpen");
      const val = !this.state['modalOpen']
      this.setState({ ['modalOpen']: val })
      this.replaceWith(this.props.videoId,nextProps.videoId)

    }
  }

  componentDidMount() {
      const wistiaLib = document.createElement("script");
      wistiaLib.src = `https://fast.wistia.net/assets/external/E-v1.js`;
      wistiaLib.async = true; 
      document.body.appendChild(wistiaLib);
      this.initTracking();
      console.log(`wistia video container id ${this.props.videoId}`)

      // For External Interstitial
      // console.log(`this video onVideoOpenEvent-${this.props.videoId}`);
      modalSubscription = SourceEmitter.addListener(`onVideoOpenEvent-${this.props.videoId}`, (data) => {
        console.log('video container push')
        this.setState({
            modalOpen: true,
        });
      });

  }

  componentWillUnmount() {
    modalSubscription && modalSubscription.remove();
  }

  toggle (key) {
    return () => {
      const val = !this.state[key]
      this.setState({ [key]: val })
      this.stopVideo()
    }
  }

  stopVideo () {
    const el = document.getElementById(this.props.videoId)
    const iframe = el.querySelector( 'iframe');
    const video = el.querySelector( 'video' )
    if ( iframe !== null ) {
        const iframeSrc = iframe.src;
        iframe.src = iframeSrc;
    }
    if ( video !== null ) {
        video.pause();
    }
  }

  initTracking(){
    // exit if no wistia
    if ( typeof window.Wistia === `undefined`) { return }
    // console.log(`HAVE WISTIA`)
    // console.log(window.Wistia)
    const video = window.Wistia.api(this.props.videoId)
    // exit if no vid 
    if ( typeof video === `undefined`) { return }
    // console.log(`video is`)
    // console.log(video)
  }

  replaceWith(oldVideoID, nextVideoID){
    
    if ( typeof window.Wistia === `undefined`) { return }
    const video = window.Wistia.api(oldVideoID)
    video.replaceWith(nextVideoID, {transition: 'crossfade', autoPlay: true})
  }

  render (){
    const { poster, url, active, smallThumb, className, videoId, carousel} = this.props

    let imgContent = ( carousel )  ? ('') : (<img className={cx({['video-smaller']:true, ['video-small-thumb']: smallThumb, [className]:className})} src={ poster } onClick={this.toggle("modalOpen")}/>)

    
    return (
      <div>
        {imgContent}
        <VideoModal {...this.state}
          toggle={this.toggle}
          url={url}
          videoId={videoId}
          
        />
      </div>
    )
  }
}

VideoContainer.propTypes = {
  children: _propType.node,
  poster: _propType.string,
  url: _propType.string,
  active: _propType.bool,
  smallThumb: _propType.bool,
  className: _propType.string,
  directLink: _propType.bool,
  videoId: _propType.string,
}


export default VideoContainer
