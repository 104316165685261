

import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import EventEmitter from '../../../lib/emitter'
import VideoCollection from '../../../lib/videos/hcp-videos'
import { navigate } from "gatsby"

import img1 from '../../../images/hero/HCP-SLA-hero-1.png';
import img2 from '../../../images/hero/HCP-SLA-hero-2.png';
import img3 from '../../../images/hero/HCP-SLA-hero-3.png';

import img1Mobile from '../../../images/hero/HCP-SLA-hero-1-mobile.png';
import img2Mobile from '../../../images/hero/HCP-SLA-hero-2-mobile.png';
import img3Mobile from '../../../images/hero/HCP-SLA-hero-3-mobile.png';

import {
    isMobile
  } from "react-device-detect";


import '../hero.scss'

const handleHero1Click = () => {
    navigate('/hcp/lh-clinical-data')
}
const handleHero2Click = () => {
    navigate('/hcp/support-center-and-savings')
}
const handleHero3Click = () => {
    EventEmitter.emit(`onVideoOpenEvent-${VideoCollection[1].wistiaID}`, true)
}


//For customizations
//http://react-responsive-carousel.js.org/storybook/index.html

class Hero extends Component {
    render() {
        return (
            <Carousel emulateTouch autoPlay infiniteLoop dynamicHeight showThumbs={false} showStatus={false} showArrows={false} interval={6000}>
                <div onClick={handleHero1Click}>
                    <img className='desktop-only' src={ (isMobile) ? img1Mobile : img1} />
                </div>
                <div onClick={handleHero2Click}>
                    <img className='desktop-only' src={ (isMobile) ? img2Mobile : img2} />
                </div>
                <div onClick={handleHero3Click}>
                    <img className='desktop-only' src={ (isMobile) ? img3Mobile : img3} />

                </div>
            </Carousel>
        );
    }
};

export default Hero;
